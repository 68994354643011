//font
$x-small-font-size: 10px;
$small-font-size: 12px;
$medium-font-size: 14px;
$large-font-size: 18px;
$x-large-font-size: 24px;
$xx-large-font-size: 36px;

//width
$max-page-width: 935px;
$max-card-width: 600px;
$timeline-wrapper-width: 590px;
$side-wrapper-width: 290px;
$profile-menu-wrapper-width: 150px;
$profile-items-wrapper-width: 450px;

//height
$menu-height: 46px;

//margin
$normal-margin: 20px auto;

//border
$input-border-buttom: 1px;

/** social logo (based on guidelines) **/
// twitter https://about.twitter.com/ja/company/brand-resources.html
$min-twitter-bird-size: 16px;
$min-twitter-bird-space: calc(16px * 0.5 / 2) // the empty space aroud the logo
