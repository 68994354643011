@import "/Users/hpapa/hube/hube-web/src/lib/_variables.scss";
.contents {
  justify-content: center;
  align-items: center;
  margin: 50px;

  .introduction {
    margin: 10px 0px;
  }

  .chapter {
    text-indent: -1em;
    padding-left: 1em;

    ol {
      counter-reset: count 0;
      list-style-type: none;
    }

    li:before {
      counter-increment: count 1;
      content: counters(count, "-") ". ";
      // padding-left: 1.5em;
    }

    p {
      text-indent: 0em;
      margin: 0px;
    }

    .title {
      margin: 10px 0px;
    }

    .content {
      padding-left: 1.5em;
      text-indent: -1em;
      padding-left: 1em;
    }

    .section {
      text-indent: -1em;

      .content {
        padding-left: 1.5em;
        text-indent: 0em;
        padding-left: 1em;
      }
    }
  }

  a {
    color: $tint-color;
    text-decoration: underline;
  }
}
