$bg-color: #2e3b4f;
$dark-bg-color: #1c1c1c;
$dark-gray-bg-color: #242424;
$gray-bg-color: #a3a3a3;
$light-gray-bg-color: #e2e2e2;
$white-bg-color: #ffffff;
$blue-bg-color: #427fe1;
$tint-color: #f40058;

$font-color: #ffffff;
$font-red-color: #c30000;
$font-blue-color: #427fe1;
$font-yellow-color: #f2b50e;
$font-green-color: #5aff19;
$font-gold-color: #caa846;
$font-silver-color: #bdc3c9;

$dark-font-color: #030c1a;
$gray-font-color: #9f9f9f;
$item-color: #ffffff;
$disable-color: #cccccc;
$border-color: #f2b50e;
$border-color-gray: #9a9a9a;
$border-color-dark-gray: #707070;
$button-font-color: #f2b50e;
$button-border-color: #f2b50e;
$form-error: #e82c0c;
$chat-bubble-user-color: #f40058;
$chat-bubble-friend-color: #eeeeee;

// hube color-set
$hube-red: #e54e7b;
$hube-red-orange: #f2b50e;
$hube-orange: #f2b50e;

$hube-gradient-start: #f2b50e;
$hube-gradient-end: #f2b50e;

$hube-grey-gradient-start: #707070;
$hube-grey-gradient-end: #999999;

/** social logo (based on guidelines) **/
// twitter https://about.twitter.com/ja/company/brand-resources.html
$twitter-color-blue: #1da1f2;
$twitter-color-white: #ffffff;
$twitter-color-green: #17bf63;