$breakpoint-sm: 320px;
$breakpoint-md: 750px;
$breakpoint-lg: 1190px;

@mixin breakpoint($breakpoint) {
    @if $breakpoint == "sm" {
        @media screen and  (max-width: #{$breakpoint-md}) {
            @content;
        }
    } @else if $breakpoint == "md" {
        @media screen and (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg}) {
            @content;
        }
    } @else if $breakpoint == "lg" {
        @media screen and (min-width: #{$breakpoint-lg}){
            @content;
        }
    } 
}

@mixin menuItem {
    width: 100%;
    height: $menu-height;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-bg-color;
    color: $font-color;
};
